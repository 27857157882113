<template>
  <div
    class="
      w-full flex justify-between items-center cursor-pointer
      border-b border-gray-100 last:border-b-0 py-12 md:py-24 px-4
      transition duration-200 even:bg-blue-50
      "
  >
    <div class="flex items-center truncate pr-4">
      <div class="flex flex-col truncate">
        <p class="font-semibold text-14 md:text-18 mb-4 leading-tight truncate">
          {{ resource.name }}
        </p>
        <p class="text-gray-700 text-12 md:text-base leading-tight truncate">{{ resource.domain }}</p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row items-end md:items-center">
      <AppButton
        variant="primary"
        size="standart-sm"
        class="mb-8 md:mr-8 md:mb-0"
        @click="onActionButtonClick('onEditClick')"
      >
        Edit
        <i class="icon-edit-pencil hidden md:block text-12 ml-4" />
      </AppButton>
      <AppButton
        variant="danger"
        size="standart-sm"
        @click="onActionButtonClick('onDeleteClick')"
      >
        Delete
        <i class="icon-bin hidden md:block text-12 ml-4" />
      </AppButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import AppButton from '@/components/stateless/AppButton.vue'

export default defineComponent({
  name: 'ResourceItemRow',

  components: {
    AppButton
  },

  props: {
    resource: {
      required: true,
      type: Object
    }
  },

  emits: ['onEditClick', 'onDeleteClick'],

  setup (props, { emit }) {
    const onActionButtonClick = (value) => {
      emit(value, props.resource)
    }

    return {
      onActionButtonClick
    }
  }
})
</script>
