<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <AppEmptyPage v-if="!resources.length">
      <template #title>You have not added any resources yet</template>
      <template #action>
        <AppButton
          :to="{ name: routeNames.addResource }"
          variant="primary"
          size="standart-sm"
        >
          Add
          <i class="icon-plus text-12 ml-8" />
        </AppButton>
      </template>
    </AppEmptyPage>

    <template v-else>
      <!-- Header -->
      <div class="flex justify-between items-center pb-8 border-b-2 border-gray-200">
        <h3 class="text-base md:text-18 leading-tight font-bold font-heading text-gray-800 truncate pr-16">
          Resources
        </h3>

        <!-- Action buttons -->
        <div class="flex-shrink-0">
          <AppButton
            :to="{ name: routeNames.addResource }"
            variant="primary"
            size="standart-sm"
          >
            Add
            <i class="icon-plus text-12 ml-8" />
          </AppButton>
        </div>
      </div>

      <div class="w-full flex flex-1 flex-col overflow-y-auto">
        <ResourceItemRow
          v-for="resource in resources"
          :key="resource.id"
          :resource="resource"
          @onEditClick="onEditClickHandler"
          @onDeleteClick="onDeleteClickHandler"
        />

        <AppPagination
          class="py-16"
          :current-page="page"
          :total="total"
          @updateCurrentPage="onUpdatePageHandler"
        />
      </div>
    </template>

    <AppConfirmModal
      v-model="deleteResourceConfirmModal"
      @onConfirmClick="onDeleteConfirmHandler"
      @onCancelClick="onDeleteCancelHandler"
    >
      <template #modal-title>
        Are you sure you want to delete - {{ selectedResourceToDelete.name }}
      </template>
    </AppConfirmModal>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useResources, usePagination } from '@/core/compositions'
import { notification } from '@/core/helpers'
import { routeNames } from '@/router'

import AppEmptyPage from '@/components/stateless/layout/AppEmptyPage.vue'
import AppButton from '@/components/stateless/AppButton.vue'
import AppConfirmModal from '@/components/stateless/modals/AppConfirmModal.vue'
import AppPagination from '@/components/stateless/AppPagination.vue'

import ResourceItemRow from './components/ResourceItemRow.vue'

export default defineComponent({
  name: 'Resources',

  components: {
    AppEmptyPage,
    AppButton,
    AppConfirmModal,
    AppPagination,

    ResourceItemRow
  },

  setup () {
    const router = useRouter()
    const { resources, getResources, deleteResource } = useResources()
    const {
      page,
      total,
      size,
      onUpdatePage,
      onUpdateTotal,
      setPaginationFromQuery
    } = usePagination(routeNames.resources)

    const loading = ref(true)
    const deleteResourceConfirmModal = ref(false)
    const selectedResourceToDelete = ref(null)

    onMounted(() => {
      setPaginationFromQuery()
      getInitialData()
    })

    const getInitialData = async () => {
      loading.value = true

      try {
        const result = await getResources({ page: page.value, size: size.value })

        onUpdateTotal(result.count)
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    const deleteResourceHandler = async (resourceId) => {
      loading.value = true

      try {
        await deleteResource(resourceId)

        getInitialData()
      } catch (e) {
        console.log(e)
        loading.value = false
        notification()
      }
    }

    const onEditClickHandler = (resource) => {
      router.push({ name: routeNames.editResource, params: { id: resource.id } })
    }

    const onDeleteClickHandler = (resource) => {
      selectedResourceToDelete.value = resource
      deleteResourceConfirmModal.value = true
    }

    const onDeleteConfirmHandler = () => {
      deleteResourceConfirmModal.value = false
      deleteResourceHandler(selectedResourceToDelete.value.id)
      selectedResourceToDelete.value = null
    }

    const onDeleteCancelHandler = () => {
      selectedResourceToDelete.value = null
      deleteResourceConfirmModal.value = false
    }

    const onUpdatePageHandler = (page) => {
      onUpdatePage(page)

      getInitialData()
    }

    return {
      routeNames,
      loading,
      resources,
      deleteResourceConfirmModal,
      onDeleteConfirmHandler,
      onDeleteCancelHandler,
      selectedResourceToDelete,
      onEditClickHandler,
      onDeleteClickHandler,

      // Pagination
      page,
      total,
      onUpdatePageHandler
    }
  }
})
</script>
